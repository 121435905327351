<template>
  <div class='content-container flex flex-col justify-start items-stretch'>
    <portal to='headerSpace'>
      <div class='flex flex-row justify-start items-center gap-x-4'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>고유재산 보유현황</h1>
      </div>
    </portal>
    <div class='flex flex-row my-6'>
      <el-radio-group
        v-model='collectStatus'
        size='large'
        class='px-4 ml-3 mt-1'>
        <el-radio-button label='전체'></el-radio-button>
        <el-radio-button label='진행중'></el-radio-button>
        <el-radio-button label='회수완료'></el-radio-button>
      </el-radio-group>
    </div>
    <div class='flex-grow table-container overflow-auto px-2 lg:px-8 bg-white'>
      <table class='w-full text-xs'>
        <thead>
          <tr class='border-b whitespace-no-wrap text-left'>
            <th class='px-2 py-2 sticky top-0 z-10'>구분</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky top-0 z-10'>종목명</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky top-0 z-10'>세부명</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky top-0 z-10'>최초투자시점</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky border-r top-0 z-10'>최종회수시점</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky top-0 z-10 text-right'>투자수량</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky top-0 z-10 text-right'>회수수량</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky top-0 z-10 text-right'>보유수량</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky top-0 z-10 text-right'>투자단가</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky top-0 z-10 text-right'>장부상단가</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky border-r top-0 z-10 text-right'>평가손익률</th>
            <th class='px-2 py-2 sticky top-0 z-10 text-right'>최초투자원금</th>
            <th class='px-2 py-2 sticky top-0 z-10 text-right'>원금회수</th>
            <th class='px-2 py-2 sticky top-0 z-10 text-right'>회수손익</th>
            <th class='px-2 py-2 sticky top-0 z-10 text-right'>잔여원금</th>
            <th class='px-2 py-2 sticky top-0 z-10 text-right'>장부가액</th>
            <th class='px-2 py-2 sticky top-0 z-10 text-right'>평가손익</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky top-0 z-10 border-l text-right'>발행주식수(천)</th>
            <th v-if='isShowHeaders' class='px-2 py-2 sticky top-0 z-10 text-right'>지분율(%)</th>
          </tr>
          <tr class='border-b whitespace-no-wrap text-left'>
            <th class='px-4 py-2'>
              합계
              <span class='tabular-units-text' style='text-indent: 0.5rem;'>
                {{ filteredData.length }}
              </span>
            </th>
            <th v-if='isShowHeaders' class='px-2 py-2'></th>
            <th v-if='isShowHeaders' class='px-2 py-2'></th>
            <th v-if='isShowHeaders' class='px-2 py-2'></th>
            <th v-if='isShowHeaders' class='px-2 py-2 border-r'></th>
            <th v-if='isShowHeaders' class='px-2 py-2'></th>
            <th v-if='isShowHeaders' class='px-2 py-2'></th>
            <th v-if='isShowHeaders' class='px-2 py-2'></th>
            <th v-if='isShowHeaders' class='px-2 py-2'></th>
            <th v-if='isShowHeaders' class='px-2 py-2'></th>
            <th v-if='isShowHeaders' class='px-2 py-2 border-r'></th>
            <th class='px-2 py-2 text-right tabular-number'>{{ formatWon(total('investmentValue')) }}</th>
            <th class='px-2 py-2 text-right tabular-number'>{{ formatWon(total('collectPrincipal')) }}</th>
            <th class='px-2 py-2 text-right tabular-number'>{{ formatWon(total('collectGain')) }}</th>
            <th class='px-2 py-2 text-right tabular-number'>{{ formatWon(total('principalBalance')) }}</th>
            <th class='px-2 py-2 text-right tabular-number'>{{ formatWon(total('acquiredValue')) }}</th>
            <th class='px-2 py-2 text-right tabular-number' :class='profitLossText(total("unrealizedGain"))'>
              {{ gainSign(total("unrealizedGain")) }}{{ formatWon(total('unrealizedGain')) }}
            </th>
            <th v-if='isShowHeaders' class='px-2 py-2 border-l'></th>
            <th v-if='isShowHeaders' class='px-2 py-2'></th>
          </tr>
        </thead>
        <proprietary-portfolio-table v-for='accountingType in accountingTypes'
          :key='accountingType.codeName'
          :accounting-type='accountingType.codeName'
          :investments='groupByPiPortfolios[accountingType.codeName]'
          :state-investments='groupByStatePiPortfolios[accountingType.codeName]'
          :is-show-headers='isShowHeaders'>
        </proprietary-portfolio-table>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'
import ProprietaryPortfolioTable     from '@/views/ProprietaryPortfolioTable.vue'
import StringHelpers from '@/utils/string-helpers'
import groupBy from 'lodash/groupBy'
import dayjs from 'dayjs'
import { mapState } from 'vuex/dist/vuex.common.js'

const duration = require('dayjs/plugin/duration')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(duration)
dayjs.extend(relativeTime)

export default {
  name: 'ProprietaryPortfolio',
  components: {
    ProprietaryPortfolioTable,
  },
  data () {
    return {
      isShowAll: false,
      collectStatus: '진행중',
    }
  },
  computed: {
    ...mapState('piPortfolios', {
      statePiPortFolios: 'piPortfolios',
    }),
    ...mapMultiRowFields('piPortfolios', [
      'piPortfolios',
    ]),
    ...mapGetters('ledgerCodes', [
      'accountingTypes',
    ]),
    filteredData () {
      switch (this.collectStatus) {
        case '전체':
          return this.piPortfolios
        case '진행중':
          return this.piPortfolios.filter(investment => investment.holdingQuantity !== 0)
        case '회수완료':
          return this.piPortfolios.filter(investment => investment.holdingQuantity === 0)
        default:
          return []
      }
    },
    filteredStateData () {
      switch (this.collectStatus) {
        case '전체':
          return this.statePiPortFolios
        case '진행중':
          return this.statePiPortFolios.filter(investment => investment.holdingQuantity !== 0)
        case '회수완료':
          return this.statePiPortFolios.filter(investment => investment.holdingQuantity === 0)
        default:
          return []
      }
    },
    groupByPiPortfolios () {
      return groupBy(this.filteredData, 'accountingType')
    },
    groupByStatePiPortfolios () {
      return groupBy(this.filteredStateData, 'accountingType')
    },
    isShowHeaders () {
      return this.accountingTypes.some(accountingType => accountingType.isShowDetails === true)
    },
  },
  methods: {
    ...mapActions('piPortfolios', [
      'getPiPortfolios',
      'getTmsPiFills',
      'getTmsPiDivs',
    ]),
    ...mapActions('ledgerCodes', [
      'getLedgerCodes',
    ]),
    formatWon(number) {
      return StringHelpers.formatNumberExactDecimals(number, 0)
    },
    gainSign(number) {
      if (parseFloat(number) > 0) {
        return '+'
      } else {
        return ''
      }
    },
    profitLossText(number) {
      if (parseFloat(number) > 0) {
        return 'profit-text-color'
      } else if (parseFloat(number) < 0) {
        return 'loss-text-color'
      } else {
        return 'text-gray-500'
      }
    },
    timeAgoString(date) {
      return dayjs(date).fromNow(true)
    },
    total (column) {
      return this.filteredData.reduce((accumulator, result) => accumulator + parseFloat(result[column]), 0)
    },
  },
  mounted () {
    this.getPiPortfolios()
    this.getTmsPiFills()
    this.getTmsPiDivs()
    this.getLedgerCodes('accounting_type')
  },
}
</script>

<style lang='scss' scoped>
  // .table-container {
  //   height: calc(100vh - 10rem);
  // }

  @media (min-width: 1024px) {
    .content-container {
      height: calc(100vh - 6rem);
    }
    // .table-container {
    //   height: calc(100vh - 12rem);
    // }
  }
</style>
