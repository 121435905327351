<template>
  <tr class='bg-white border-b border-gray-100 hover:bg-blue-100'
    :key='investment.id'>
    <td @click='openDetail' class='px-2 py-2 text-left cursor-pointer' style='text-indent: 3.5rem;'>
      {{ investment.shareCode }}
    </td>
    <td class='px-2 py-2 text-left whitespace-no-wrap'>{{ investment.name }}</td>
    <td class='px-2 py-2 text-left whitespace-no-wrap'>{{ investment.name2 }}</td>
    <td class='px-2 py-2 text-left tabular-number'>
      <div>
        {{ investment.firstBuyDate }}
      </div>
      <div class='tabular-units-text'>
        {{ timeAgoString(investment.firstBuyDate) }}
      </div>
    </td>
    <td class='px-2 py-2 text-left tabular-number border-r'>
      <div>
        {{ investment.latestSellDate }}
      </div>
      <div class='tabular-units-text'>
        {{ timeAgoString(investment.latestSellDate) }}
      </div>
    </td>

    <td class='px-2 py-2 text-right tabular-number'>{{ formatQuantity(investment.investmentQuantity) }}</td>
    <td class='px-2 py-2 text-right tabular-number'>{{ formatQuantity(investment.collectQuantity) }}</td>
    <td class='px-2 py-2 text-right tabular-number'>{{ formatQuantity(holdingQuantity) }}</td>
    <td class='px-2 py-2 text-right tabular-number'>{{ formatWon(investment.investmentPrice )}}</td>
    <td class='px-2 py-2 text-right tabular-number'>
      <vue-numeric
        v-model='investment.bookPrice'
        output-type='number'
        class='form-input text-xs text-right'
        style='width: 100px; font-size: 0.7rem;'
        separator=','
        :precision='6'
        @change='updateBookPrice'>
      </vue-numeric>
    </td>
    <td class='px-2 py-2 text-right tabular-number border-r' :class='profitLossText(priceRatio)'>
      {{ gainSign(priceRatio) }}{{ formatPercentage(priceRatio) }}
    </td>
    <td class='px-2 py-2 text-right tabular-number'>{{ formatWon(investment.investmentValue) }}</td>
    <td class='px-2 py-2 text-right tabular-number'>{{ formatWon(investment.collectPrincipal) }}</td>
    <td class='px-2 py-2 text-right tabular-number'>{{ formatWon(investment.collectGain) }}</td>
    <td class='px-2 py-2 text-right tabular-number'>{{ formatWon(investment.principalBalance) }}</td>
    <td class='px-2 py-2 text-right tabular-number'>{{ formatWon(bookAmount) }}</td>
    <td class='px-2 py-2 text-right tabular-number' :class='profitLossText(unrealizedGain)'>
      {{ gainSign(unrealizedGain) }}{{ formatWon(unrealizedGain) }}
    </td>
    <td class='px-2 py-2 text-right tabular-number border-l'>{{ formatQuantity(investment.totalIssueQuantity) }}</td>
    <td class='px-2 py-2 text-right tabular-number'>{{ formatPercentage(investment.ownershipRatio) }}</td>
  </tr>
</template>

<script>
import StringHelpers from '@/utils/string-helpers'
import dayjs from 'dayjs'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ProprietaryPortfolioTableRow',
  props: [
    'investment',
  ],
  computed: {
    ...mapState('piPortfolios', [
      'piPortfolios',
    ]),
    ...mapState('dashboardViews', [
      'currentDashboardView',
      'dashboardViews',
    ]),
    storeInvestment () {
      return this.piPortfolios.find(data => data.id === this.investment.id)
    },
    calculateType () {
      if (['A', 'J', 'U'].includes(this.investment.shareCode[0])) {
        return 'X'
      } else {
        return ''
      }
    },
    priceRatio () {
      return this.investment.investmentPrice === 0 ? 0 : (this.investment.bookPrice / this.investment.investmentPrice - 1) * 100
    },
    holdingQuantity () {
      return this.investment.investmentQuantity - this.investment.collectQuantity
    },
    bookAmount () {
      return this.investment.bookPrice * this.holdingQuantity
    },
    unrealizedGain () {
      return this.bookAmount - this.investment.principalBalance
    },
  },
  methods: {
    ...mapActions('piPortfolios', [
      'setBookPrice',
      'piPortfolioDetailsOpen',
    ]),
    ...mapActions('investments', [
      'getInvestmentData',
    ]),
    ...mapActions('dashboardViews', [
      'getDashboardViews',
      'selectDashboardView',
    ]),
    formatQuantity(number) {
      return number ? StringHelpers.formatNumberExactDecimals(number, 0) : 0
    },
    formatBookPrice(number) {
      return StringHelpers.formatNumberExactDecimals(number, 2)
    },
    formatWon(number) {
      return StringHelpers.formatNumberExactDecimals(number, 0)
    },
    formatPercentage(number) {
      return StringHelpers.formatNumberExactDecimals(number, 2)
    },
    formatUkWon(number) {
      return StringHelpers.ukWonString(number, 2)
    },
    gainSign(number) {
      if (parseFloat(number) > 0) {
        return '+'
      } else {
        return ''
      }
    },
    profitLossText(number) {
      if (parseFloat(number) > 0) {
        return 'profit-text-color'
      } else if (parseFloat(number) < 0) {
        return 'loss-text-color'
      } else {
        return 'text-gray-500'
      }
    },
    timeAgoString(date) {
      return date ? `(${dayjs(date).fromNow(true)})` : ''
    },
    updateBookPrice () {
      this.setBookPrice(this.investment.id)
    },
    openDetail () {
      this.setListEditView()
      this.getInvestmentData(this.investment.investmentId).then(() => {
        this.piPortfolioDetailsOpen(this.investment)
      })
    },
    setListEditView () {
      if (this.currentDashboardView.view_layout === 'List Edit' && this.currentDashboardView.dashboard_view_name.substring(0,9) === '[고유] Deal') {
        return
      }

      if (this.dashboardViews.length === 0) {
        this.getDashboardViews ()
      }

      for (var ii = 0; ii < this.dashboardViews.length; ii++) {
        if (this.dashboardViews[ii].view_layout === 'List Edit' && this.dashboardViews[ii].dashboard_view_name.substring(0,9) === '[고유] Deal') {
          this.selectDashboardView(this.dashboardViews[ii])
          break
        }
      }
      return
    },
  },
}
</script>

<style lang='scss' scoped>
  // .table-container {
  //   height: calc(100vh - 10rem);
  // }

  @media (min-width: 1024px) {
    .content-container {
      height: calc(100vh - 6rem);
    }
    // .table-container {
    //   height: calc(100vh - 12rem);
    // }
  }
</style>
