<template>
  <tbody>
    <tr v-if='investmentsCount > 0'
      @click='toggleDetails'
      class='bg-white border-b border-gray-100 hover:bg-blue-100 cursor-pointer'>
      <td class='px-2 py-2 whitespace-no-wrap'>
        <component :is='toggleIcon' class='pb-1 h-4 inline-block' />
        {{ accountingType }}
        <span class='tabular-number' style='text-indent: 0.5rem;'>
          {{ investmentsCount }}
        </span>
      </td>
      <td v-if='isShowHeaders' class='px-2 py-2'></td>
      <td v-if='isShowHeaders' class='px-2 py-2'></td>
      <td v-if='isShowHeaders' class='px-2 py-2'></td>
      <td v-if='isShowHeaders' class='px-2 py-2 border-r'></td>
      <td v-if='isShowHeaders' class='px-2 py-2'></td>
      <td v-if='isShowHeaders' class='px-2 py-2'></td>
      <td v-if='isShowHeaders' class='px-2 py-2'></td>
      <td v-if='isShowHeaders' class='px-2 py-2'></td>
      <td v-if='isShowHeaders' class='px-2 py-2'></td>
      <td v-if='isShowHeaders' class='px-2 py-2 border-r'></td>
      <td class='px-2 py-2 text-right tabular-number'>{{ formatWon(subTotal(investments, 'investmentValue')) }}</td>
      <td class='px-2 py-2 text-right tabular-number'>{{ formatWon(subTotal(investments, 'collectPrincipal')) }}</td>
      <td class='px-2 py-2 text-right tabular-number'>{{ formatWon(subTotal(investments, 'collectGain')) }}</td>
      <td class='px-2 py-2 text-right tabular-number'>{{ formatWon(subTotal(investments, 'principalBalance')) }}</td>
      <td class='px-2 py-2 text-right tabular-number'>{{ formatWon(subTotal(investments, 'bookAmount')) }}</td>
      <td class='px-2 py-2 text-right tabular-number' :class='profitLossText(subTotal(investments, "unrealizedGain"))'>
        {{ gainSign(subTotal(investments, "unrealizedGain")) }}{{ formatWon(subTotal(investments, 'unrealizedGain')) }}
      </td>
      <td v-if='isShowHeaders' class='px-2 py-2 border-l'></td>
      <td v-if='isShowHeaders' class='px-2 py-2'></td>
    </tr>
    <template v-for='investment in investments'>
      <proprietary-portfolio-table-row
        v-if='isShowDetails'
        :investment='investment'
        :key='`${investment.id}-${investment.name}`'>
      </proprietary-portfolio-table-row>
    </template>
  </tbody>
</template>

<script>
import { ChevronDownIcon, ChevronRightIcon } from 'vue-feather-icons'
import { mapMutations } from 'vuex'
import ProprietaryPortfolioTableRow  from '@/views/ProprietaryPortfolioTableRow.vue'
import StringHelpers from '@/utils/string-helpers'
import dayjs from 'dayjs'

const duration = require('dayjs/plugin/duration')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(duration)
dayjs.extend(relativeTime)

export default {
  name: 'ProprietaryPortfolioTable',
  props: [
    'accountingType',
    'investments',
    'stateInvestments',
    'isShowHeaders',
  ],
  components: {
    ChevronDownIcon,
    ChevronRightIcon,
    ProprietaryPortfolioTableRow,
  },
  data () {
    return {
      isShowDetails: false,
    }
  },
  computed: {
    toggleIcon () {
      return this.isShowDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    investmentsCount () {
      return this.investments ? this.investments.length : 0
    },
  },
  methods: {
    ...mapMutations('ledgerCodes', [
      'setAccountingTypeShowDetails',
    ]),
    formatWon(number) {
      return StringHelpers.formatNumberExactDecimals(number, 0)
    },
    gainSign(number) {
      if (parseFloat(number) > 0) {
        return '+'
      } else {
        return ''
      }
    },
    profitLossText(number) {
      if (parseFloat(number) > 0) {
        return 'profit-text-color'
      } else if (parseFloat(number) < 0) {
        return 'loss-text-color'
      } else {
        return 'text-gray-500'
      }
    },
    timeAgoString(date) {
      return dayjs(date).fromNow(true)
    },
    subTotal (data, column) {
      return data ? data.reduce((accumulator, result) => {
        const bookAmount = parseFloat(result['bookPrice'] * (result['investmentQuantity'] - result['collectQuantity']))
        const principalBalance = parseFloat(result['principalBalance'])
        if (column === 'bookAmount') {
          return accumulator + bookAmount
        } else if (column === 'unrealizedGain') {
          return accumulator + (bookAmount - principalBalance)
        } else {
          return accumulator + parseFloat(result[column])
        }
      }, 0) : 0
    },
    toggleDetails () {
      this.isShowDetails = !this.isShowDetails
      this.setAccountingTypeShowDetails({ accountingType: this.accountingType, isShowDetails: this.isShowDetails })
    },
  },
}
</script>

<style lang='scss' scoped>
  // .table-container {
  //   height: calc(100vh - 10rem);
  // }

  @media (min-width: 1024px) {
    .content-container {
      height: calc(100vh - 6rem);
    }
    // .table-container {
    //   height: calc(100vh - 12rem);
    // }
  }
</style>
